import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import Button from '../components/Button'
import useSession from '../hooks/useSession'

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
  padding: 2rem 0;
`

const Title = styled.h1`
  margin-bottom: .5rem;
`

const Subtitle = styled.h3`
  opacity: .2;
`

const ConnectedControls = styled.div`
  margin-top: 1rem;
`

const LoginView = () => {
  const session = useSession()

  if (!session.isInitialized) {
    return
  }

  if (session.isLoggedIn) {
    const profileLink = `/${session.user.username}`

    return (
      <Component>
        <Title>
          Your wallet is already connected.
        </Title>
        <ConnectedControls>
          <Link href={profileLink}>
            <Button
              variant={Button.VARIANT_ALT}
              rounded
            >
              Your profile
            </Button>
          </Link>
        </ConnectedControls>
      </Component>
    )
  }

  return (
    <Component>
      <Title>
        Please connect your wallet first
      </Title>
      <Subtitle>
        Not supported on mobile (yet)
      </Subtitle>
    </Component>
  )
}

export default LoginView
